import angular = require('angular');
import moment = require('moment');
import { ICreditoDesconto } from '../models/creditoDesconto.model';
import { IEvento } from '../models/evento.model';
import { MeurhApontamentosService } from '../../../Apontamentos/apontamentos.service';

export class MeurhCreditosdescontosListController {

    static $inject = ['$rootScope', '$stateParams', '$location', 'MeurhCreditosdescontosService', '$state', 'MeurhApontamentosService', 'NewToaster'];

    public busy: boolean = false;

    public entities: Array<Partial<ICreditoDesconto>>;

    public filterSituacao: string = '';
    public filterFolha: string = '';
    public filterDescricao: IEvento = undefined;
    public constructors: any = this.entityService.constructors = [];
    public filters: any;

    constructor(
        public $rootScope: angular.IRootScopeService & { configuracoes: any, nsjGlobals: any, },
        public $stateParams: angular.ui.IStateParamsService,
        public $location: angular.ILocationService,
        public entityService: any,
        public $state: angular.ui.IStateService,
        public MeurhApontamentosService: MeurhApontamentosService,
        public NewToaster: any,
    ) {
        this.busy = true;
        entityService.filter = $stateParams['q'] ? $stateParams['q'] : '';
        this.filters = entityService.filters = {};
        // this.entities = this.entityService.reload();

        $rootScope.$on('meurh_solicitacoescreditodesconto_list_finished', (event: any, args: any) => {
            this.busy = false;
        });
        $rootScope.$on('meurh_solicitacoescreditodesconto_loaded', (event: any, args: any) => {
            this.busy = false;

        });
        $rootScope.$on('meurh_solicitacoescreditodesconto_deleted', () => {
            this.busy = false;
            this.filterReload();
        });
        $rootScope.$on('meurh_solicitacoescreditodesconto_submitted', () => {
            this.busy = false;
            this.filterReload();
        });
    }

    async $onInit() {

        await this.MeurhApontamentosService._load({ 'tipo': '-1', '#': null }, {}, '')
        .then((listaApontamentos: any) => {

            let dataAtual = moment().format('YYYY-MM-DD');

            let apontamentosValidos = listaApontamentos.filter((apontamento) => {
                let dataApontamento = moment(apontamento.data_fechamento_alteracoes).format('YYYY-MM-DD');
                return moment(dataApontamento).isSameOrAfter(moment(dataAtual));
            });

            if (apontamentosValidos.length < 1) {
                this.$rootScope.configuracoes.EXISTE_PERIODO_ABERTO = false;
            } else {

                let apontamentoValido;

                if (apontamentosValidos.length === 1) {
                    apontamentoValido = apontamentosValidos[0];
                } else {
                    apontamentoValido = apontamentosValidos.reduce((objetoAnterior, objetoAtual) => {
                        if (!objetoAnterior || moment(objetoAtual.data_fechamento_alteracoes, 'YYYY-MM-DD').isBefore(moment(objetoAnterior.data_fechamento_alteracoes, 'YYYY-MM-DD'))) {
                          return objetoAtual;
                        } else {
                          return objetoAnterior;
                        }
                    }, null);
                }

                this.$rootScope.configuracoes.EXISTE_PERIODO_ABERTO = true;
                this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_ADIANTAMENTO = moment(apontamentoValido.data_pagamento_adiantamento, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_ADIANTAMENTO = moment(apontamentoValido.data_fechamento_alt_adiantamento, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.$rootScope.nsjGlobals.a.configuracoes.DATA_FECHAMENTO_FOLHA = moment(apontamentoValido.data_fechamento_alteracoes, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.$rootScope.nsjGlobals.a.configuracoes.DATA_PAGAMENTO_FOLHA = moment(apontamentoValido.data_pagamento_folha, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                this.$rootScope.nsjGlobals.a.configuracoes.COMPETENCIA_MES_ANO = apontamentoValido.mesreferencia.toString().padStart(2, '0') +
                    '/' + apontamentoValido.anoreferencia;
                this.$rootScope.nsjGlobals.a.configuracoes.EXIBE_ADIANTAMENTO = apontamentoValido.permite_envio_adiantamento;
            }
        });

        this.filterReload();
    }

    isBusy() {
        return this.entityService.loadParams.busy;
    }

    search() {
        if (this.entityService.filter !== '') {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters, { 'q': this.entityService.filter }));
        } else {
            this.$location.path(this.$location.path()).search(angular.extend({}, this.entityService.filters));
        }
        var filter = { search: this.entityService.filter, filters: angular.copy(this.entityService.filters) };
        return this.entityService.search(filter);
    }

    loadMore() {
        this.entityService.loadMore();
    }

    filterReload() {
        if (this.constructors.hasOwnProperty('situacao')) {
            delete this.constructors.situacao;
        }
        if (this.constructors.hasOwnProperty('tipocalculo')) {
            delete this.constructors.tipocalculo;
        }
        if (this.constructors.hasOwnProperty('evento')) {
            delete this.constructors.evento;
        }

        if (this.filterSituacao !== '') {
            this.constructors.situacao = this.filterSituacao;
        }
        if (this.filterFolha !== '') {
            this.constructors.tipocalculo = this.filterFolha;
        }
        if (this.filterDescricao) {
            this.constructors.evento = this.filterDescricao.evento;
        }

        this.entityService.constructors = this.constructors;
        this.entities = this.entityService.reload();
    }

    goToCreate () {
        if (!this.$rootScope.configuracoes.EXISTE_PERIODO_ABERTO) {

            this.NewToaster.pop({
                type: 'warning',
                title: 'Não há período aberto',
                body: 'Não existe um período aberto para lançamento do movimento.',
                timeout: 10000
            });
        } else {
            this.$state.go('creditosdescontos', {'passoumativo': 1});
        }
    }

}
